import React, { useState, useContext } from "react";
import { useAuth } from "../components/Firebase";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ErrorMessage } from "../components/common";
import { FirebaseContext } from "../components/Firebase";

// Material-ui imports
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Input,
} from "@material-ui/core";

import LockRoundedIcon from "@material-ui/icons/LockRounded";

const Register = () => {
  const { firebase } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: ""
  });

  function handleInputChange(e) {
    e.persist();
    setErrorMessage("");
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (formValues.password == formValues.confirmPassword) {
      firebase
        .register({
          username: formValues.username,
          twitter: formValues.twitterHandle,
          email: formValues.email,
          password: formValues.password
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage("Password doesn not match!");
    }

    console.log(formValues);
  }

  return (
    <Container maxWidth="sm">
      <Grid item xs={12}>
        <Box className="form-block">
          <Box className="icon-heading">
            <Avatar>
              <LockRoundedIcon />
            </Avatar>
            <Typography variant="h4" component="h2">
              Sign up
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
          <TextField
              onChange={handleInputChange}
              value={formValues.username}
              variant="outlined"
              required
              fullWidth
              name="username"
              label="User Name"
              type="text"
              
            />  
            <TextField
              onChange={handleInputChange}
              value={formValues.twitterHandle}
              variant="outlined"
              required
              fullWidth
              name="twitterHandle"
              label="Twitter Handle"
              type="text"
            />  

            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              
            />
            <TextField
              onChange={handleInputChange}
              value={formValues.password}
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              minLength={6}
            />
            <TextField
              onChange={handleInputChange}
              value={formValues.confirmPassword}
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              minLength={6}
            />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Sign up
            </Button>
          </form>
          <Link href="/login">Already have an account? Log in</Link>
        </Box>
      </Grid>
    </Container>
  );
};

export default Register;
